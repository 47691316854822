import Image from 'next/image';
import Link from 'next/link';

import {
  Button,
  HamburgerMenu,
  Text,
  TextWithIcon,
  Tooltip
} from '@/components/atomic/nuclei';
import {
  CART_LIST_TYPE,
  CART_STATUS,
  DEVICE_SURFACE,
  ORDER_STATUS,
  PAYMENT_SOURCE_TYPE,
  staticMediaStoreBaseURL
} from '@/config/common';
import copyToClipBoard from '@/lib/copyToClipBoard';
import { GetUiDeviceSurface } from '@/lib/screenResolution';
import { convertToShortMonthDateFormat } from '@/lib/time';
import { parseNumberedLabel } from '@/lib/utils';
import { getEditOrderURL } from '@/services/opsPortal.service';
import { generatePaymentLink } from '@/services/payment.service';

const tooltipConfig = [
  {
    target: 'more-action',
    text: 'More Action'
  }
];

const CartInnerBody = ({
  cartCreatedBy,
  createdAt,
  isMobile = false,
  isOrdered,
  orderId,
  orderNumber = '',
  orderStatusClass
}) => {
  const cartKeyValuePairs = [
    {
      alt: 'event planner brand icon',
      className: '',
      icon: 'planner-brand-icon-v1.svg',
      iconHeight: 20,
      iconWidth: 20,
      id: 'event-planner-name',
      label: cartCreatedBy,
      labelStyle: `text-nero text-sm font-light ${orderStatusClass}`,
      tooltipText: !isMobile && 'Event Planner Name'
    },
    {
      alt: 'calender icon',
      className: '',
      icon: 'calendar-brand-icon.svg',
      iconHeight: 20,
      iconWidth: 20,
      id: 'cart-creation-date',
      label: convertToShortMonthDateFormat(createdAt),
      labelStyle: `text-nero text-sm font-light ${orderStatusClass}`,
      tooltipText: !isMobile && 'Cart Creation Date'
    },
    {
      alt: 'order icon',
      className: '',
      icon: 'order-brand-icon-v1.svg',
      iconHeight: 20,
      iconWidth: 20,
      id: 'order-number',
      label: parseNumberedLabel(orderNumber),
      labelStyle: `text-nero text-sm font-light cursor-pointer ${isMobile ? '' : 'hover:text-brand'} ${orderStatusClass}`,
      onClick: () => window.open(getEditOrderURL({ orderId }), '_blank'),
      tooltipText: !isMobile && 'Order Number',
      visible: isOrdered
    }
  ];
  return (
    <div
      className={`flex flex-wrap gap-4 flex-row gap-y-4 text-sm mt-3 ${isMobile ? 'justify-between' : ''} ${orderStatusClass}`}
    >
      {cartKeyValuePairs.map(
        (
          {
            alt,
            className,
            icon,
            iconHeight,
            iconWidth,
            id,
            label,
            labelStyle,
            onClick = () => {},
            tooltipText,
            visible = true
          },
          index
        ) => (
          <TextWithIcon
            key={index}
            {...{
              alt,
              className,
              icon,
              iconHeight,
              iconWidth,
              id,
              label,
              labelStyle,
              onClick,
              onHoverCopy: true,
              show: visible,
              tooltipText
            }}
          />
        )
      )}
    </div>
  );
};

const handlePaymentLinkClick = async ({
  id,
  paymentSourceType,
  setShowToast,
  userId
}) => {
  const isClipboardAvailable = Boolean(navigator.clipboard);
  if (isClipboardAvailable) {
    await copyToClipBoard(
      generatePaymentLink({
        paymentSourceType,
        referenceId: id,
        userId
      })
    );
  }
  setShowToast({
    message: isClipboardAvailable
      ? 'Successfully generated and copied payment link'
      : 'Failed to copy to clipboard',
    show: true,
    successToast: isClipboardAvailable
  });
};

const EventCartCardBodyCTA = ({
  id,
  onClickCopyQuoteLink,
  setShowToast,
  status,
  userInfo
}) => {
  const showLinks = [CART_STATUS.COLLABORATE.value].includes(status);
  const userId = userInfo?.id;
  return (
    showLinks && (
      <div className='flex items-center md:gap-3 gap-2 mt-2 md:mt-0 md:self-center'>
        <Button
          {...{
            alt: 'Copy Payment Link',
            className:
              'cursor-pointer leading-6 text-brand border border-brand text-sm rounded px-3 md:px-2 py-[1px] h-7',
            iconHeight: 16,
            iconPosition: 'Left',
            iconURL: `${staticMediaStoreBaseURL}/icons/copy-brand-icon-v3.svg`,
            iconWidth: 16,
            id: 'paymentLink',
            label: 'Payment Link',
            onClick: (event) => {
              event.stopPropagation();
              handlePaymentLinkClick({
                setShowToast,
                userId,
                id,
                paymentSourceType: PAYMENT_SOURCE_TYPE.CART
              });
            },
            width: 'w-[150px]'
          }}
        />
        <Button
          {...{
            alt: 'Copy Web Quote Link',
            className:
              'cursor-pointer leading-6 text-brand border border-brand text-sm rounded px-3 md:px-2 py-[2px] h-7',
            iconHeight: 16,
            iconPosition: 'Left',
            iconURL: `${staticMediaStoreBaseURL}/icons/copy-brand-icon-v3.svg`,
            iconWidth: 16,
            id: 'customerLink',
            label: 'Web Quote Link',
            onClick: onClickCopyQuoteLink,
            width: 'w-[170px]'
          }}
        />
      </div>
    )
  );
};

const OrderedCartCardBodyCTA = ({
  hidePaymentLinkForOrderCart,
  orderId,
  setShowToast,
  userInfo
}) => (
  <div className='flex items-center md:gap-3 gap-2 mt-2 md:mt-0 md:self-center'>
    {!hidePaymentLinkForOrderCart && (
      <Button
        {...{
          alt: 'paymentLink copy',
          className:
            'cursor-pointer leading-6 text-brand border border-brand text-sm rounded px-3 md:px-2 py-[1px] h-7',
          iconGroupClassName: 'gap-1',
          iconHeight: 16,
          iconPosition: 'Left',
          iconURL: `${staticMediaStoreBaseURL}/icons/copy-brand-icon-v3.svg`,
          iconWidth: 16,
          id: 'paymentLink',
          label: 'Payment Link',
          onClick: (event) => {
            event.stopPropagation();
            handlePaymentLinkClick({
              id: orderId,
              paymentSourceType: PAYMENT_SOURCE_TYPE.ORDER,
              setShowToast,
              userId: userInfo?.id
            });
          },
          width: 'w-[155px]'
        }}
      />
    )}

    <div>
      <Link
        className='flex items-center gap-1 bg-white border border-brand text-brand text-sm font-medium rounded px-2 py-[2px] h-7'
        href={getEditOrderURL({ orderId })}
        onClick={(e) => e.stopPropagation()}
        target='_blank'
      >
        <div className='flex items-center w-6 h-6 self-center'>
          <Image
            {...{
              alt: 'manage order',
              height: 0,
              src: `${staticMediaStoreBaseURL}/icons/open-new-tab-gradient.svg`,
              style: { height: 20, width: 20 },
              width: 0
            }}
          />
        </div>
        <Text {...{ className: 'self-center', content: 'Manage Order' }} />
      </Link>
    </div>
  </div>
);

const CartCardBodyCTAType = {
  [CART_LIST_TYPE.ARCHIVE]: EventCartCardBodyCTA,
  [CART_LIST_TYPE.EVENT]: EventCartCardBodyCTA,
  [CART_LIST_TYPE.ORDER]: OrderedCartCardBodyCTA
};

const CartCardBodyWeb = ({
  CartCardBodyCTA,
  cartCreatedBy,
  cartOrder,
  createdAt,
  id,
  isOrdered,
  menuOptions,
  onClickCopyQuoteLink,
  orderStatusClass,
  setShowMenu,
  setShowToast,
  showMenu,
  status,
  userInfo
}) => (
  <div className='flex flex-col md:flex-row items-center justify-between'>
    <CartInnerBody
      {...{
        cartCreatedBy,
        createdAt,
        isOrdered,
        orderId: cartOrder?.order.id,
        orderNumber: cartOrder?.order.orderNumber,
        orderStatusClass
      }}
    />
    <div className='flex gap-3'>
      <CartCardBodyCTA
        {...{
          hidePaymentLinkForOrderCart: true,
          id,
          onClickCopyQuoteLink,
          orderId: cartOrder?.order.id,
          setShowToast,
          status,
          userInfo
        }}
      />
      <HamburgerMenu
        {...{
          menuOptions,
          setShowMenu,
          showMenu
        }}
      />
    </div>
    <Tooltip {...{ configList: tooltipConfig }} />
  </div>
);

const CartCardBodyMobile = ({
  CartCardBodyCTA,
  cartCreatedBy,
  cartOrder,
  createdAt,
  id,
  isOrdered,
  onClickCopyQuoteLink,
  orderStatusClass,
  setShowToast,
  status,
  userInfo
}) => (
  <div className='flex flex-col md:flex-row gap-2 text-sm'>
    <CartInnerBody
      {...{
        cartCreatedBy,
        createdAt,
        isMobile: true,
        isOrdered,
        orderId: cartOrder?.order.id,
        orderNumber: cartOrder?.order.orderNumber,
        orderStatusClass
      }}
    />
    <div className='flex gap-3'>
      <CartCardBodyCTA
        {...{
          hidePaymentLinkForOrderCart: true,
          id,
          onClickCopyQuoteLink,
          orderId: cartOrder?.order.id,
          setShowToast,
          status,
          userInfo
        }}
      />
    </div>
  </div>
);

const CartCardBody = ({
  cartCreatedBy,
  cartListType,
  cartOrder,
  createdAt,
  id,
  menuOptions,
  onClickCopyQuoteLink,
  setShowMenu,
  setShowToast,
  showMenu,
  status,
  userInfo
}) => {
  const surface = GetUiDeviceSurface();
  const CartCardBodyMap = {
    [DEVICE_SURFACE.MOBILE]: CartCardBodyMobile,
    [DEVICE_SURFACE.WEB]: CartCardBodyWeb
  };

  const CartBody = CartCardBodyMap[surface];

  const CartCardBodyCTA = CartCardBodyCTAType[cartListType];
  const isOrdered = status === CART_STATUS.ORDER.value;
  const isOrderInCancelledState =
    isOrdered && cartOrder.order.status === ORDER_STATUS.CANCELLED.value;
  const orderStatusClass = isOrderInCancelledState ? 'cancelled' : '';

  return (
    <CartBody
      {...{
        CartCardBodyCTA,
        cartCreatedBy,
        cartOrder,
        createdAt,
        id,
        isOrdered,
        menuOptions,
        onClickCopyQuoteLink,
        orderStatusClass,
        setShowMenu,
        setShowToast,
        showMenu,
        status,
        userInfo
      }}
    />
  );
};

export default CartCardBody;
