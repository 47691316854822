import { Fragment } from 'react';

import { getEPICExplanationStringInputs } from '@/services/userEventCartList.service';

const EPICExplanationWithoutTooltip = ({
  className = 'md:text-sm text-xs leading-6',
  epicParameters,
  id
}) => (
  <div
    className={className}
    id={id}
  >
    {getEPICExplanationStringInputs({
      epicParameters
    }).map(({ prefix, value }) => (
      <Fragment key={value}>
        {prefix || ''}
        <b>{value}</b>
      </Fragment>
    ))}
  </div>
);

export default EPICExplanationWithoutTooltip;
