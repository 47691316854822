import Image from 'next/image';

import { Button, InputLabel, Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { useIsMobile } from '@/lib/screenResolution';
import { checkIsEmptyOrDummyEventDetail } from '@/services/userEventCartList.service';

const AddressButton = ({ eventAddress, setShowAddAddress }) => (
  <div>
    <Button
      {...{
        className: `w-full text-orange text-sm md:text-base font-medium rounded-md border border-orange py-1 leading-4 focus:outline-none`,
        label: 'Add Address',
        onClick: () => setShowAddAddress(true)
      }}
    />

    {eventAddress && (
      <Text
        className='mt-1 w-full text-red-500 text-xs font-medium capitalize invalid-feedback'
        content={eventAddress.message}
      />
    )}
  </div>
);

const FormatedAddress = ({
  buildingName,
  disabled,
  formattedAddress,
  houseNumber,
  iconURL,
  landmark,
  setShowAddAddress,
  userAddress
}) => {
  const [isMobile] = useIsMobile();
  const dummyValueErrorStyle = checkIsEmptyOrDummyEventDetail({
    key: 'formattedAddress',
    value: formattedAddress
  })
    ? 'text-error-base'
    : '';
  const addressParts = [houseNumber, buildingName, landmark, formattedAddress];
  const formattedAddressString = addressParts
    .filter((elem) => elem && elem.trim())
    .join(', ');
  return (
    <div className='mt-2 relative'>
      {iconURL && (
        <span className={`absolute place-content-center md:h-12 h-10 left-2`}>
          <Image
            {...{
              alt: 'location-icon',
              src: `${staticMediaStoreBaseURL}/icons/${iconURL}`,
              height: 0,
              style: { width: isMobile ? 20 : 24, height: isMobile ? 20 : 24 },
              width: 0
            }}
          />
        </span>
      )}
      {userAddress && (
        <Text
          className={`border border-platinum rounded-lg pl-10 md:py-3 md:h-12 h-10 md:text-base text-sm place-content-center ${!disabled ? 'hover:border-brand cursor-pointer' : ''} ${dummyValueErrorStyle}
`}
          content={formattedAddressString}
        />
      )}
      {!disabled && (
        <InputLabel
          className={
            'block md:whitespace-normal text-sm font-Montserrat text-cinnabar font-medium text-right mt-2 me-1 cursor-pointer'
          }
        >
          <Text
            content='Change Address'
            onClick={() => setShowAddAddress(true)}
          />
        </InputLabel>
      )}
    </div>
  );
};

const ShowFormattedAddress = ({
  disabled = false,
  errors,
  iconURL,
  setShowAddAddress,
  setValue,
  userAddress
}) => {
  const {
    buildingName,
    eventAddress,
    formattedAddress,
    houseNumber,
    landmark
  } = userAddress;
  if (formattedAddress !== '') {
    setValue('location', formattedAddress);
  }
  return (
    <div className='flex flex-col w-full'>
      <Text
        {...{
          className: 'md:text-base text-sm font-medium text-nero',
          content: 'Event Location *',
          HtmlTag: 'label'
        }}
      />
      {formattedAddress || disabled ? (
        <FormatedAddress
          {...{
            buildingName,
            disabled,
            formattedAddress,
            houseNumber,
            iconURL,
            landmark,
            setShowAddAddress,
            userAddress
          }}
        />
      ) : (
        <AddressButton
          {...{
            dbName: 'location',
            eventAddress,
            setShowAddAddress
          }}
        />
      )}
      {errors?.location && (
        <Text
          {...{
            className:
              'mt-1 w-full text-red-500 text-xs font-medium capitalize invalid-feedback',
            content: errors.location.message,
            HtmlTag: 'span'
          }}
        />
      )}
    </div>
  );
};

export default ShowFormattedAddress;
