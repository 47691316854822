import { SelectFormInput, Text } from '@/components/atomic/nuclei';
import { useIsMobile } from '@/lib/screenResolution';
import { EVENT_PROFILE_FORM_STEPS } from '@/services/hostEvent.service';

const TerminateEventStep = ({
  formActiveStepId,
  clearErrors,
  control,
  isUserEventDetailInEditMode,
  leadExitReasons,
  errors,
  getValues,
  register,
  setValue
}) => {
  const [isMobile] = useIsMobile();

  const formStepVisibilityClass =
    formActiveStepId === EVENT_PROFILE_FORM_STEPS.TERMINATE.id
      ? 'block'
      : 'hidden';

  return (
    <div
      className={formStepVisibilityClass}
      id={EVENT_PROFILE_FORM_STEPS.TERMINATE.id}
    >
      <div className={`flex flex-col gap-2 md:px-8 ${isMobile ? 'p-4' : ''}`}>
        <Text
          {...{
            className: 'text-nero text-base font-medium',
            content: 'Terminate Event'
          }}
        />
        <SelectFormInput
          {...{
            clearErrors,
            control,
            dbName: 'leadExitReasonId',
            disabled: !isUserEventDetailInEditMode,
            dropdownPlacement: 'bottom',
            errors,
            selectContainerClassName: 'border border-platinum rounded',
            name: 'lead exit reason',
            getValues,
            placeholderLabel: 'reason for Non-Conversion',
            register,
            setValue,
            values: leadExitReasons
          }}
        />
      </div>
    </div>
  );
};

export default TerminateEventStep;
