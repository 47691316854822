import _ from 'lodash';
import Image from 'next/image';
import { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';

import {
  Button,
  CloseIcon,
  InputField,
  Text
} from '@/components/atomic/nuclei';
import {
  ACTION_MODAL_SOURCE,
  staticMediaStoreBaseURL,
  VERIFICATION_STATUSES
} from '@/config/common';
import debounce from '@/lib/debounce';

const { FAILED, UNCHECKED, VERIFIED } = VERIFICATION_STATUSES;

const CommonIcon = ({ alt, icon }) => (
  <div className='absolute text-white text-sm font-medium py-1 rounded top-0 left-full -ml-8 mt-3'>
    <Image
      {...{
        alt,
        height: 0,
        src: `${staticMediaStoreBaseURL}/icons/${icon}`,
        style: { height: 16, width: 16 },
        width: 0
      }}
    />
  </div>
);

const ValidIcon = () => (
  <CommonIcon
    {...{
      alt: 'verify status',
      icon: 'check-completed-green-icon.svg'
    }}
  />
);

const InValidIcon = () => (
  <CommonIcon
    {...{
      alt: 'failed status',
      icon: 'error-red-icon.svg'
    }}
  />
);

const VerifyCTA = ({ inputValue, onClickVerify }) => (
  <Button
    {...{
      className:
        'bg-brand-gradient absolute text-white text-sm font-medium py-1 rounded top-0 left-full -ml-16 mt-2',
      disabled: !inputValue?.length,
      label: 'Verify',
      onClick: onClickVerify,
      width: 'w-14'
    }}
  />
);

const VerificationStatusesCTA = {
  [FAILED]: InValidIcon,
  [UNCHECKED]: VerifyCTA,
  [VERIFIED]: ValidIcon
};

const VerificationForm = ({
  inputValue,
  invalidMessage,
  onClickVerify,
  onInputChange,
  placeholder,
  verificationStatus,
  verifiedContentName
}) => {
  const VerificationStatusCTA = VerificationStatusesCTA[verificationStatus];

  return (
    <div className='flex flex-col relative my-2'>
      <div>
        <InputField
          {...{
            className: 'rounded-md',
            onChange: onInputChange,
            placeholder,
            type: 'number',
            value: inputValue
          }}
        />
        {invalidMessage && (
          <div className='text-xs text-red-500'>{invalidMessage}</div>
        )}
        <VerificationStatusCTA
          {...{
            inputValue,
            onClickVerify
          }}
        />
      </div>
      {verifiedContentName && (
        <Text
          {...{
            className: 'text-sm font-light my-1 mt-2',
            content: verifiedContentName
          }}
        />
      )}
    </div>
  );
};

const ActionModal = ({
  actionModalRequest,
  ctaLabel,
  entityNameKey,
  onClickVerify: onClickVerifyRoutine,
  onSubmitRequestConfig,
  placeholder,
  setActionModalRequest,
  source,
  title
}) => {
  const [inputValue, setInputValue] = useState(null);
  const [invalidMessage, setInvalidMessage] = useState(null);
  const [targetEntity, setTargetEntity] = useState(null);
  const [verificationStatus, setVerificationStatus] = useState(UNCHECKED);

  const closeActionModal = () => {
    setTargetEntity(null);
    setInvalidMessage(null);
    setVerificationStatus(UNCHECKED);
    setInputValue(null);
    setActionModalRequest(null);
  };

  const onClickVerify = () =>
    onClickVerifyRoutine({
      inputValue,
      setInvalidMessage,
      setTargetEntity,
      setVerificationStatus
    });

  const onInputChange = (e) => {
    debounce(setInputValue(e.target.value));
    setVerificationStatus(UNCHECKED);
    setTargetEntity(null);
    setInvalidMessage(null);
  };

  const executeRequestOnClick = () => {
    let additionalParameterForOnClick = {};
    switch (source) {
      case ACTION_MODAL_SOURCE.ADD_ITEM_TO_CART:
        additionalParameterForOnClick = { verifiedCartDetail: targetEntity };
        break;
      case ACTION_MODAL_SOURCE.IMPORT_CART:
        additionalParameterForOnClick = { cartNumber: inputValue };
        break;
      case ACTION_MODAL_SOURCE.EXPORT_CART:
        additionalParameterForOnClick = { userEventNumber: inputValue };
        break;
      default:
        break;
    }
    onSubmitRequestConfig.routine({
      ...additionalParameterForOnClick,
      ...onSubmitRequestConfig.parameters,
      closeActionModal,
      setInvalidMessage
    });
  };

  const isCTADisabled = Boolean(_.isEmpty(targetEntity));
  const verifiedContentName = targetEntity?.[entityNameKey];

  return (
    <Modal
      backdrop={true}
      centered
      fade={false}
      isOpen={Boolean(actionModalRequest)}
    >
      <ModalBody>
        <div className='flex h-screen justify-center p-4'>
          <div className='self-center relative bg-white px-5 rounded-lg w-[424px]'>
            <div className='flex justify-end pt-4 mb-4 relative left-3 -top-1'>
              <CloseIcon {...{ onClick: closeActionModal }} />
            </div>
            <Text
              {...{
                className:
                  'md:text-xl text-lg font-medium text-nero mb-3 text-center',
                content: title
              }}
            />
            <VerificationForm
              {...{
                inputValue,
                invalidMessage,
                onClickVerify,
                onInputChange,
                placeholder,
                verificationStatus,
                verifiedContentName
              }}
            />
            <Button
              {...{
                className:
                  'bg-brand-gradient text-white text-sm font-medium py-3 rounded mt-4 mb-4',
                disabled: isCTADisabled,
                label: ctaLabel,
                onClick: executeRequestOnClick
              }}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ActionModal;
