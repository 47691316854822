import Image from 'next/image';

import { Text } from '@/components/atomic/nuclei';
import { getImageURL } from '@/helpers/carousel';
import { useIsMobile } from '@/lib/screenResolution';

const IMAGE_GALLERY_ROW_COUNT = 6;

const UserEventMedia = ({
  userEventMediaList,
  openImageModal,
  setShowImageGalleryModal
}) => {
  const [isMobile] = useIsMobile();
  const imageGalleryRowCount = isMobile ? 2 : IMAGE_GALLERY_ROW_COUNT;
  return (
    <div>
      <div className='flex justify-between md:my-6 my-3'>
        <div className='self-center flex gap-5'>
          <Text
            {...{
              content: 'Event Media',
              className:
                'text-nero text-base md:text-2xl font-medium font-Montserrat flex self-center'
            }}
          />
        </div>
        <div className='flex'>
          <Text
            {...{
              className: 'text-dim-gray text-sm font-medium cursor-pointer',
              content: 'View All',
              onClick: () => setShowImageGalleryModal(true)
            }}
          />
        </div>
      </div>
      <div
        className={`md:py-5 md:my-5 py-2 my-2 md:border text-sm md:text-base border-platinum rounded-lg px-4 md:px-6 bg-lightgray font-medium shadow-event cursor-pointer`}
      >
        <div className={isMobile ? 'flex gap-4 m-5' : 'flex gap-6'}>
          {userEventMediaList
            .slice(0, imageGalleryRowCount)
            .map(({ source, url }, index) => (
              <a
                className='md:w-30 md:h-30 rounded-2.5'
                key={index}
                onClick={() => openImageModal(userEventMediaList[index])}
              >
                <Image
                  alt='userevent-media'
                  className='rounded-2.5'
                  height={0}
                  id='user-event-order-media'
                  src={getImageURL({ source, url })}
                  style={{ height: 132, width: 132 }}
                  unoptimized
                  width={0}
                />
              </a>
            ))}
        </div>
      </div>
    </div>
  );
};

export default UserEventMedia;
