import timeList from '@/config/timeList';

const TimeDropDown = ({
  className,
  disabled = false,
  eventTime,
  handleEventTime
}) => {
  const DropdownOptions = () =>
    timeList.map(({ time, value }, key) => (
      <option
        key={key}
        value={value}
      >
        {time}
      </option>
    ));

  return (
    <select
      className={`${
        eventTime ? 'text-nero' : 'text-silver'
      } font-medium bg-white outline-none border-0 ms-2 w-full cursor-pointer appearance-none md:py-2 md:text-base text-sm ${className}`}
      disabled={disabled}
      onChange={handleEventTime}
      value={eventTime}
    >
      <option
        disabled
        value=''
      >
        Choose Event Time
      </option>
      <DropdownOptions />
    </select>
  );
};

export default TimeDropDown;
