import Image from 'next/image';

import {
  Text,
  TextKeyValuePair,
  TextWithIcon,
  Tooltip
} from '@/components/atomic/nuclei';
import { mediaStoreBaseURL, USER_EVENT_STAGE } from '@/config/common';
import { parseNumberedLabel } from '@/lib/utils';
import { getLabelForEventRequestIsEndToEnd } from '@/services/userEventCartList.service';

const EventCartListSideBarHead = ({
  displayHostEventProfile,
  isMobile,
  userEvent
}) => {
  const {
    stage,
    userEventDetails: [{ checkoutEvent = {} } = {}] = [],
    userEventNumber,
    zendeskTicketDetail
  } = userEvent;
  const { eventRequestIsEndToEnd } = zendeskTicketDetail || {};

  const tooltipConfigList = [
    {
      target: 'event-stage',
      text: 'Event Stage'
    }
  ];

  return (
    <div className='flex flex-col md:gap-4 gap-1'>
      <div className='flex items-center gap-4'>
        <div className={`${isMobile ? 'w-auto' : 'w-24'} rounded-lg`}>
          <Image
            alt='Event'
            className='rounded-lg'
            height={0}
            id='user-event-icon'
            priority={true}
            src={`${mediaStoreBaseURL}/${checkoutEvent.iconUrl}`}
            style={{
              height: isMobile ? 64 : 100,
              width: isMobile ? 64 : 100
            }}
            unoptimized={true}
            width={0}
          />
        </div>
        <TextKeyValuePair
          {...{
            className: 'flex-col md:!gap-2 !gap-1',
            label: checkoutEvent?.event.name,
            labelClass:
              'text-brand md:text-xl text-base md:w-72 w-64 truncate-text font-semibold',
            spaceTop: 'mt-0',
            value: getLabelForEventRequestIsEndToEnd(eventRequestIsEndToEnd),
            valueClassName: 'md:text-base text-xs font-medium text-nero'
          }}
        />
      </div>
      <div className='flex flex-col'>
        <div className='flex justify-between mb-2 mt-1 gap-2 sm:gap-4'>
          <div className='self-center'>
            <TextWithIcon
              {...{
                className:
                  'flex border border-brand md:rounded-lg rounded px-2 py-1 items-center gap-2 cursor-pointer sm:gap-4',
                icon: 'view.svg',
                onClick: displayHostEventProfile,
                label: `${isMobile ? 'View' : 'Event Profile'}`,
                labelStyle:
                  'text-brand text-sm font-medium cursor-pointer self-center leading-4'
              }}
            />
          </div>
          <div className='flex flex-wrap gap-2 sm:gap-4'>
            {stage && (
              <Text
                {...{
                  className: `${USER_EVENT_STAGE[stage].class} rounded-lg px-2 py-1 text-sm font-medium self-center flex`,
                  content: USER_EVENT_STAGE[stage].label,
                  id: 'event-stage'
                }}
              />
            )}
            <TextWithIcon
              {...{
                className:
                  'bg-white border-neutral border rounded-lg px-3 py-1 self-center flex',
                icon: 'user-event-brand-icon.svg',
                id: 'event-number',
                label: parseNumberedLabel(userEventNumber),
                labelStyle: 'text-sm font-medium text-dim-gray',
                onHoverCopy: true,
                tooltipText: 'Event Number'
              }}
            />
          </div>
        </div>
      </div>
      <Tooltip {...{ configList: tooltipConfigList }} />
    </div>
  );
};

export default EventCartListSideBarHead;
