import { InputField } from '@/components/atomic/nuclei';

const EPMFormRow = ({ inputProps }) => (
  <div className='flex flex-col md:flex-row md:gap-4 gap-3 w-full'>
    {inputProps.map(
      (
        {
          alt,
          className,
          dbName,
          disabled,
          errors,
          iconURL,
          inputGroup,
          label,
          labelClass,
          placeholder,
          register,
          type = 'text',
          ...rest
        },
        index
      ) => (
        <InputField
          key={index}
          {...{
            alt,
            className,
            dbName,
            disabled,
            errors,
            iconURL,
            inputGroup,
            label,
            labelClass,
            placeholder,
            register,
            type,
            ...rest
          }}
        />
      )
    )}
  </div>
);

export default EPMFormRow;
